import Vue from "vue";
import VueRouter from "vue-router";
import login from "../components/login";
import AuthGuard from "./auth-guard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/chat",
    name: "messages",
    component: () =>
      import(/* webpackChunkName: "chatlist" */ "../components/chat"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/orders",
    name: "orders",
    component: () =>
      import(/* webpackChunkName: "orders" */ "../components/orders"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/order-details/:mid",
    name: "order details",
    // route level code-splitting
    // this generates a separate chunk (order-details.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "order-details" */ "../components/order-details"
      ),
    beforeEnter: AuthGuard,
  },
  {
    path: "/route-map/:mid",
    name: "route map",
    component: () =>
      import(/* webpackChunkName: "route-map" */ "../components/route-map"),
    beforeEnter: AuthGuard,
  },
  // {
  //   path: "/photos/:mid",
  //   name: "photos",
  //   component: () =>
  //     import(/* webpackChunkName: "signature" */ "../components/photos"),
  //   beforeEnter: AuthGuard,
  // },
  {
    path: "/camera/:mid",
    name: "photos",
    component: () =>
      import(/* webpackChunkName: "signature" */ "../components/camera"),
    beforeEnter: AuthGuard,
  },  
  {
    path: "/signature/:mid",
    name: "signature",
    component: () =>
      import(/* webpackChunkName: "signature" */ "../components/signature"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../components/settings"),
    beforeEnter: AuthGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
