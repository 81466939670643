<template>
  <div class="login">
    <v-container class="my-2">
      <v-card flat class="mt-5">
        <v-layout row wrap justify-space-around>
          <v-flex xs12 sm8 md6 class="pa-5">
            <v-form
              ref="loginform"
              class="air-form"
              @keyup.native.enter="loginUser"
            >
              <v-text-field
                label="Domain"
                v-model="domain"
                id="domain"
                class="styled-input"
                ref="domaintxt"
                :rules="[rules.required]"
                :value="this.domain"
                color="orange"
                onkeyup="this.value = this.value.toLowerCase();"
              ></v-text-field>
              <v-text-field
                label="Username"
                v-model="username"
                id="username"
                class="styled-input"
                :rules="[rules.required, rules.min3]"
                :value="this.username"
                color="orange"
                onkeyup="this.value = this.value.toLowerCase();"
              ></v-text-field>
              <v-text-field
                label="Password"
                v-model="password"
                id="password"
                class="styled-input"
                :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min6]"
                :value="this.password"
                :type="showpass ? 'text' : 'password'"
                hint="At least 6 characters"
                counter
                color="orange"
                @click:append="showpass = !showpass"
              ></v-text-field>
              <div class="my-3"><br /></div>
              <div class="text-right mx-0 mt-5">
                <v-btn
                  color="orange darken-1"
                  class="white--text"
                  depressed
                  block
                  large
                  @click.prevent="loginUser"
                  >Log in</v-btn
                >
              </div>
            </v-form>
          </v-flex>
        </v-layout>
      </v-card>
      <v-layout row v-if="message.text">
        <v-flex xs12 sm6 px-1 ma-0>
          <app-alert
            :textAlert="message.text"
            :typeAlert="message.type"
            @dismissed="onDismissed()"
          ></app-alert>
        </v-flex>
      </v-layout>         
    </v-container> 
  </div>
</template>

<script>
export default {
  name: "Login",
  mounted: function() {
    localStorage.removeItem("vuex-key");
    this.focusInput();
  },
  computed: {
    message() {
      return this.$store.getters.message;
    },
  },
  methods: {
    focusInput() {
      this.$refs.domaintxt.focus();
    },
    loginUser() {
      if (this.$refs.loginform.validate()) {
        this.$store.dispatch("loginUser", {
          domain: this.domain.toLowerCase().replace(/\s/g, ""),
          username: this.username.toLowerCase().replace(/\s/g, ""),
          password: this.password,
        });
      }
    },
    onDismissed() {
      this.$store.dispatch("clearMessage");
    },
  },
  components: {},
  data: () => ({
    showpass: false,
    domain: "",
    username: "",
    password: "",
    rules: {
      required: (value) => !!value || "Required",
      min3: (v) => v.length >= 3 || "Min 3 characters",
      min6: (v) => v.length >= 6 || "Min 6 characters",
      emailMatch: () => "The email and password you entered don't match",
    },
  }),
};
</script>
