<template>
  <v-dialog 
    transition="dialog-top-transition"
    v-model="dialog" 
    persistent 
    max-width="80%">
    <template v-slot:activator="{ on }">
      <v-row>
        <v-col cols="12">
          <v-btn
            fab
            depressed
            small
            right
            class="transparent grey--text text--darken-1 font-weight-bold logout"
            v-on="on"
          >
            <v-icon>mdi-location-exit</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-card>
      <v-card-title class="title">{{ dialogdata.title }}</v-card-title>
      <v-card-text class="subtitle-1 mt-5">{{ dialogdata.text }}</v-card-text>
      <v-card-actions>
        <v-btn
          color="grey darken-1"
          class="white--text"
          depressed
          @click="dialog = false"
          v-if="dialogdata.btn_show_cancel"
          >{{ dialogdata.btn_cancel }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          color="orange darken-1"
          class="white--text"
          depressed
          @click="btn_action()"
          >{{ dialogdata.btn_save }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Dialog",
  props: ["dialogdata"],
  methods: {
    btn_action() {
      //logout
      if (this.dialogdata.btn_action == "logout") {
        this.$store.dispatch("logoutUser");
        if (
          this.$route.name == "order details" ||
          this.$route.name == "route map" ||
          this.$route.name == "photos" ||
          this.$route.name == "signature"
        ) {
          this.$router.push("./../");
        } else {
          this.$router.push("./");
        }
      } else if (this.dialogdata.btn_action == "ok") {
        this.dialog = false;
      }
    },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>
