<template>
  <nav>
    <v-app-bar app flat class="grey lighten-2 orange--text text--darken-1">
      <v-icon
        fab
        depressed
        class="transparent grey--text text--darken-1 font-weight-bold"
        @click="goBackAction()"
        v-if="
          this.$route.name == 'order details' ||
            this.$route.name == 'route map' ||
            this.$route.name == 'photos' ||
            this.$route.name == 'signature'
        "
        >mdi-backburger</v-icon
      >
      <v-app-bar-nav-icon
        fab
        depressed
        small
        class="transparent grey--text text--darken-1 font-weight-bold"
        @click.stop="toggleDrawer()"
        v-if="
          this.$route.name != 'order details' &&
            this.$route.name != 'route map' &&
            this.$route.name != 'photos' &&
            this.$route.name != 'signature'
        "
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title
        light
        class="text-uppercase text-center font-weight-bold"
        >{{ this.$route.name }}</v-toolbar-title
      >
      <app_dialog :dialogdata="dialogdata" />
    </v-app-bar>
    <v-navigation-drawer app light v-model="drawer">
      <v-list class="py-0">
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-icon>
            <v-icon
              :class="[link.route != '' ? 'orange--text' : 'grey--text']"
              >{{ link.icon }}</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              :class="[
                link.route != ''
                  ? 'text-uppercase orange--text'
                  : 'text-uppercase grey--text',
              ]"
              >{{ link.text }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item :href="'tel:' + this.officephone">
          <v-list-item-icon>
            <v-icon :class="'orange--text'">mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :class="'text-uppercase orange--text'"
              >Call Dispatch</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list three-line flat>
        <v-list-item>
          <template>
            <v-list-item-action>
              <v-checkbox
                v-model="allownotifications"
                color="orange"
                disabled
                @change="saveNotificationsSettings(allownotifications)"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Notifications</v-list-item-title>
              <v-list-item-subtitle class="caption">Notify me when I received new orders / messages</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list>
      <v-row justify="center" class="mt-5">
        <v-img
          v-if="this.company_logo != null"
          width="180px"
          max-width="200px"
          :src="company_logo"
        ></v-img>         
        <v-img
          v-else
          width="140px"
          max-width="140px"
          src="../../assets/logo-client.jpg"
        />
        <v-col class="text-center pt-2 caption font-weight-bold" cols="12">
          {{ driver }}
        </v-col>
      </v-row>
      <v-footer flat inset absolute class="justify-center pl-0" height="50px">
        <v-col class="text-center pt-2 caption" cols="12">
          <span v-html="copyright"></span>
          2011 - {{ new Date().getFullYear() }}
          xDriver v4.7.7
        </v-col>
      </v-footer>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import app_dialog from "./dialog";
export default {
  name: "Navigation",
  mounted: function() {
    if (this.company_logo == null && this.$store.getters.user.company_logo != null) {
      this.company_logo = this.$store.getters.domain + '/' + this.$store.getters.user.company_logo //replace placeholder
    }
  },  
  methods: {
    saveNotificationsSettings(allownotifications) {
      //save Notifications settings: subscribe or unsubscribe
      console.log(allownotifications);
    },
    goBackAction() {
      this.$store.dispatch("clearMessage");
      if (this.$route.name == "order details") {
        this.$router.push("./../orders");
      } else if (
        this.$route.name == "route map" ||
        this.$route.name == "photos" ||
        this.$route.name == "signature"
      ) {
        this.$router.push("./../order-details/" + this.$route.params.mid);
      }
    },
    toggleDrawer() {
      this.$store.dispatch("clearMessage");
      this.drawer = !this.drawer;
    },
  },
  computed: {   
    driver() {
      return this.$store.getters.user.username.toUpperCase();
    },
    officephone() {
      return this.$store.getters.user.officephone;
    },  
  },
  components: {
    app_dialog,
  },
  data() {
    return {
      copyright: "&copy;",
      drawer: false,
      allownotifications: false,
      dialogdata: {
        title: "xDriver",
        text: "Are you sure you want to logout?",
        btn_save: "Log out",
        btn_cancel: "Cancel",
        btn_show_cancel: true,
        btn_action: "logout",
      },
      links: [
        { icon: "mdi-view-dashboard", text: "Orders", route: "/orders" },
        { icon: "mdi-cog-outline", text: "Settings", route: "/settings" },
        { icon: "mdi-message-text", text: "Messages", route: "" },
      ],
      company_logo: null
    };
  },
};
</script>
