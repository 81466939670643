<template>
  <v-app class="grey lighten-4">
    <v-card flat class="mb-4" height="100%" v-if="$route.name != 'login'">
      <navigation />
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-card>
    <v-card flat class="mb-4" height="100%" v-else>
      <v-main>
        <login />
      </v-main>
    </v-card>
    <v-card flat class="mt-4" height="50px">
      <v-footer absolute>
        <v-col style="text-align: -webkit-center;">         
          <v-img
            max-width="400px"
            max-height="50px"
            src="./assets/draymate.png"
          ></v-img>
        </v-col>
      </v-footer>
    </v-card>
  </v-app>
</template>

<script>
import navigation from "./components/layout/navigation";
import login from "./components/login";

export default {
  name: "App",
  components: {
    navigation,
    login,
  }, 
  data: () => ({}),
};
</script>

<style>
.v-footer {
  position: fixed;
}
.v-btn {
  border-radius: 4px;
}
.logout {
  float: right;
  padding: 0px;
  margin: 0px -10px 0px 0px;
}
.v-input input {
  max-height: unset !important;
  height: 40px !important;
  font-size: 23px;
}
input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}
input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
</style>
