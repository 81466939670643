<template>
  <v-snackbar :timeout="timeout" v-model="showAlert" top centered :color="this.typeAlert">
    <span>{{ textAlert }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn
        fab
        depressed
        small
        right
        v-bind="attrs"
        class="transparent white--text"
        @click="onClose()"
      >
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: ["textAlert", "typeAlert"],
  data: () => ({
    timeout: -1,
    showAlert() {
      return this.textAlert == null ? false : true;
    },
  }),
  methods: {
    onClose() {
      this.$emit("dismissed");
    },
  },
};
</script>
