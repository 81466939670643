var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('v-app-bar',{staticClass:"grey lighten-2 orange--text text--darken-1",attrs:{"app":"","flat":""}},[(
        this.$route.name == 'order details' ||
          this.$route.name == 'route map' ||
          this.$route.name == 'photos' ||
          this.$route.name == 'signature'
      )?_c('v-icon',{staticClass:"transparent grey--text text--darken-1 font-weight-bold",attrs:{"fab":"","depressed":""},on:{"click":function($event){return _vm.goBackAction()}}},[_vm._v("mdi-backburger")]):_vm._e(),(
        this.$route.name != 'order details' &&
          this.$route.name != 'route map' &&
          this.$route.name != 'photos' &&
          this.$route.name != 'signature'
      )?_c('v-app-bar-nav-icon',{staticClass:"transparent grey--text text--darken-1 font-weight-bold",attrs:{"fab":"","depressed":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer()}}}):_vm._e(),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"text-uppercase text-center font-weight-bold",attrs:{"light":""}},[_vm._v(_vm._s(this.$route.name))]),_c('app_dialog',{attrs:{"dialogdata":_vm.dialogdata}})],1),_c('v-navigation-drawer',{attrs:{"app":"","light":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"py-0"},[_vm._l((_vm.links),function(link){return _c('v-list-item',{key:link.text,attrs:{"router":"","to":link.route}},[_c('v-list-item-icon',[_c('v-icon',{class:[link.route != '' ? 'orange--text' : 'grey--text']},[_vm._v(_vm._s(link.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:[
              link.route != ''
                ? 'text-uppercase orange--text'
                : 'text-uppercase grey--text',
            ]},[_vm._v(_vm._s(link.text))])],1)],1)}),_c('v-list-item',{attrs:{"href":'tel:' + this.officephone}},[_c('v-list-item-icon',[_c('v-icon',{class:'orange--text'},[_vm._v("mdi-phone")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:'text-uppercase orange--text'},[_vm._v("Call Dispatch")])],1)],1)],2),_c('v-divider'),_c('v-list',{attrs:{"three-line":"","flat":""}},[_c('v-list-item',[[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"orange","disabled":""},on:{"change":function($event){return _vm.saveNotificationsSettings(_vm.allownotifications)}},model:{value:(_vm.allownotifications),callback:function ($$v) {_vm.allownotifications=$$v},expression:"allownotifications"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Notifications")]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v("Notify me when I received new orders / messages")])],1)]],2)],1),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[(this.company_logo != null)?_c('v-img',{attrs:{"width":"180px","max-width":"200px","src":_vm.company_logo}}):_c('v-img',{attrs:{"width":"140px","max-width":"140px","src":require("../../assets/logo-client.jpg")}}),_c('v-col',{staticClass:"text-center pt-2 caption font-weight-bold",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.driver)+" ")])],1),_c('v-footer',{staticClass:"justify-center pl-0",attrs:{"flat":"","inset":"","absolute":"","height":"50px"}},[_c('v-col',{staticClass:"text-center pt-2 caption",attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.copyright)}}),_vm._v(" 2011 - "+_vm._s(new Date().getFullYear())+" xDriver v4.7.7 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }